<template>
    <div>
        <sermon-table
            :in-group="inGroup"
            :in-series="inSeries"
            :show-search-bar="false"
            :show-series="false"
            :show-scripture="false"
            :show-direct="true"
            :sort.prop="{ date: 'asc' }"
        ></sermon-table>
    </div>
</template>

<script>
export default {
  name: 'series-table',
  props: {
    inGroup: {
      default: '',
      type: String,
      required: true
    },
    inSeries: {
      default: '',
      type: String,
      required: true
    }
  }
};
</script>
