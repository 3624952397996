import config from "./config.js";

export default {
  singleSermon(id) {
    return jQuery
      .ajax({
        url: config.getSermonsHost() + "/sermon/list",
        method: "GET",
        data: {
          filter: {
            id: id
          }
        }
      });
  },
  sermons(filter, limit, offset, sort, showDirect = false) {
    return jQuery
      .ajax({
        url: config.getSermonsHost() + "/sermon/list",
        method: "GET",
        data: {
          filter: filter,
          limit: limit,
          offset: offset,
          sort: sort,
          showDirect: showDirect ? 1 : 0
        }
      }).fail(function(error) {
        console.error(error);
      });
  },
  series(groupCode = null, language = null) {
    return jQuery
      .ajax({
        url: config.getSermonsHost() + "/sermon/series",
        method: "GET",
        data: {
          group: groupCode,
          language: language
        }
      }).fail(function(error) {
        console.error(error);
      });
  },

  speakers(groupCode = null, language = null) {
    return jQuery
      .ajax({
        url: config.getSermonsHost() + "/sermon/speakers",
        method: "GET",
        data: {
          group: groupCode,
          language: language
        }
      }).fail(function(error) {
        console.error(error);
      });
  },
  languages(groupCode = null) {
    return jQuery
      .ajax({
        url: config.getSermonsHost() + "/sermon/languages",
        method: "GET",
        data: {
          group: groupCode,
        }
      }).fail(function(error) {
        console.error(error);
      });
  },


  hit(id) {
    try {
      var hit = localStorage.getItem("hit_" + id);
      if (hit == id) return;
      hit = localStorage.setItem("hit_" + id, id);
      jQuery.get(config.getSermonsHost() + "/sermon/hit?id=" + id);
    } catch (e) {
      console.error(e);
    }
  }

}