var decode = function (s) {
    return decodeURIComponent(s.replace(/\+/g, " "));
};
export default {
    getUrlParams() {
        var urlParams;
        var match = true;
        var search = /([^&=]+)=?([^&]*)/g;
        var query = window.location.search.substring(1);
        urlParams = {};
        while (match) {
            match = search.exec(query);
            if(!match) break;
            urlParams[decode(match[1])] = decode(match[2]);
        }
        return urlParams;
    }
}
