<template>
        <div class="col-xs-12 sermon-list-item">
            <div class="image">
                <img v-bind:src="image" />
                <div class="playWrapper" @click="play()"></div>
            </div>
            <div class="info-box">
                <h2>{{ sermon.title }}</h2>

                <section class="all">
                    <span><b>{{ sermon.speaker }}</b></span>
                    <span v-show="sermon.scripture.length != 0">&nbsp;|&nbsp;{{ sermon.scripture }} </span>
                    <span>&nbsp;|&nbsp;{{ sermon.date }}</span> 
                    <span v-show="sermon.seriesName.length != 0">&nbsp;|&nbsp;{{ sermon.seriesName }}</span>
                    
                </section>

                <section
                    class="download-links"
                    v-html="downloadLinks"
                ></section>

                 <div class="actions">
                    <div class="action" v-if="audio" @click="play()">
                        <a><i class="fa fa-play"></i> Audio abspielen</a>
                    </div>
                    <div class="action" v-if="video" @click="play()">
                        <a><i class="fa fa-film"></i> Video abspielen</a>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import config from './config.js';
export default {
  name: 'sermon-list-item',
  props: {
    sermon : {
      type: Object,
      required: true
    }
  },
  computed: {
    image: function() {
      if (this.sermon === null || this.sermon.length === 0 || this.sermon.image === undefined) return '';
      return this.sermon.image[0];
    },
    audio: function() {
      if (this.sermon === null || this.sermon.length === 0) return '';
      if (this.sermon.audio === null || this.sermon.audio.length === 0)
        return '';
      return this.sermon.audio[0];
    },
    video: function() {
      if (this.sermon === null || this.sermon.length === 0) return '';
      if (this.sermon.video === null || this.sermon.video.length === 0)
        return '';
      return this.sermon.video[0];
    },

    staticLink: function() {
      return config.getStaticSite() + '?id=' + this.sermon.id;
    },
    downloadLinks: function() {
      if (this.sermon === null || this.sermon.length === 0) return '';
      var ret = '';
      var index;
      for (index = 0; index < this.sermon.audio.length; ++index) {
        ret +=
          "<a href='" +
          this.sermon.audio[index] +
          "' target='_blank'><i class='fa fa-headphones'></i> MP3 runterladen</a> ";
      }
      for (index = 0; index < this.sermon.video.length; ++index) {
        ret +=
          "<a href='" +
          this.sermon.video[index] +
          "' target='_blank'><i class='fa fa-film'></i> Video</a> ";
      }
      for (index = 0; index < this.sermon.other.length; ++index) {
        ret +=
          "<a href='" +
          this.sermon.other[index] +
          "' target='_blank'><i class='fa fa-file-pdf-o'></i> Dokument</a> ";
      }

      if (this.sermon.youtube) {
        ret +=
          "<a v-on:click='hit(sermon.id)' href='" +
          this.sermon.youtube +
          "'><i class='fa fa-youtube'></i> Auf Youtube anschauen</a> ";
      }
      return ret;
    }
  },
  data() {
    return {};
  },
  methods: {
    play: function() {
      this.$emit('play', this.sermon);
    }
  }
};
</script>
