<template>
    <div class="row single-sermon">
        <div class="col-xs-12"></div>
        <div class="col-xs-12 info-column">
            <div class="info-image">
                <img v-bind:src="image" />
            </div>
            <div class="info-box">
                <h2>{{ sermon.title }}</h2>

                <span class="all">
                    <span>{{ sermon.date }}</span>
                    <span>&nbsp;|&nbsp;{{ sermon.speaker }}</span>
                    <span v-show="sermon.seriesName.length != 0">&nbsp;|&nbsp;{{ sermon.seriesName }}</span>
                    <span v-show="sermon.scripture.length != 0">&nbsp;|&nbsp;{{ sermon.scripture }}</span>
                </span>

                <span
                    class="download-links"
                    v-html="downloadLinks(sermon)"
                ></span>
                <sermon-share :url="staticLink" :title="sermon.title"></sermon-share>
            </div>
        </div>
        <div class="col-xs-12">
            <sermon-player
                v-bind:audio="audio"
                v-bind:video="video"
                :title="sermon.title"
            ></sermon-player>
        </div>
    </div>
</template>

<script>
import config from './config.js';

export default {
  name: 'single-sermon-player',

  props: {
    sermon : {
      type: Object,
      required: true
    }
  },
  computed: {
    image: function() {
      if (this.sermon === null || this.sermon.length === 0) return '';
      return this.sermon.image[0];
    },
    audio: function() {
      if (this.sermon === null || this.sermon.length === 0) return '';
      if (this.sermon.audio === null || this.sermon.audio.length === 0)
        return '';
      return this.sermon.audio[0];
    },
    video: function() {
      if (this.sermon === null || this.sermon.length === 0) return '';
      if (this.sermon.video === null || this.sermon.video.length === 0)
        return '';
      return this.sermon.video[0];
    },
    staticLink: function() {
      return config.getStaticSite() + '?id=' + this.sermon.id;
    }
  },
  methods: {
    downloadLinks: function(sermon) {
      var ret = '';
      var index;
      for (index = 0; index < sermon.audio.length; ++index) {
        ret +=
          "<a href='" +
          sermon.audio[index] +
          "' target='_blank'><i class='fa fa-headphones'></i> Audio herunterladen</a> ";
      }
      for (index = 0; index < sermon.video.length; ++index) {
        ret +=
          "<a href='" +
          sermon.video[index] +
          "' target='_blank'><i class='fa fa-film'></i> Video herunterladen</a> ";
      }
      for (index = 0; index < sermon.other.length; ++index) {
        ret +=
          "<a href='" +
          sermon.other[index] +
          "' target='_blank'>Dokument herunterladen</a> ";
      }
      if (sermon.youtube) {
        ret +=
          "<a v-on:click='hit(sermon.id)' href='" +
          sermon.youtube +
          "'><i class='fa fa-youtube'></i> Auf Youtube anschauen</a> ";
      }
      return ret;
    }
  }
};
</script>
