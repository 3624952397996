<template>
    <div class="row sermon-player">
        <div class="col-xs-12">
            <video id="player" controls>
                <source>
            </video>
        </div>
        <div class="col-xs-12 change-player-type" v-show="hasOtherType">
            <a class="btn btn-default" v-on:click="setType(otherType)"><i v-bind:class="otherIcon"></i> {{otherTypeText}}</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'sermon-player',
  props: {
    audio: {
      default: '',
      type: String
    },
    video: {
      default: '',
      type: String
    },
    title: {
      default: '',
      type: String
    }
  },
  computed: {
    otherType: function() {
      if (this.type === 'video') return 'audio';
      return 'video';
    },
    otherTypeText: function() {
      if (this.type === 'video') return 'Nur die Audioaufnahme anhören';
      return 'Die Videoaufnahme ansehen';
    },
    otherIcon: function() {
      if (this.type === 'video') return 'fa fa-volume-up';
      return 'fa fa-video-camera';
    },
    hasOtherType: function() {
      if (this.type === 'video') return this.audio.length > 0;
      return this.video.length > 0;
    },
    defaultType: function() {
      if (this.video.length > 0) {
        return 'video';
      } else {
        return 'audio';
      }
    },

    type: function() {
      if (this.playerType === 'video' && this.video.length === 0)
        return 'audio';
      if (this.playerType === 'audio' && this.audio.length === 0)
        return 'video';
      return this.playerType;
    }
  },
  data: function() {
    return {
      player: null,
      playerType: 'audio'
    };
  },
  methods: {
    setup: function() {
      this.playerType = this.defaultType;
      if (typeof plyr == 'undefined') {
        var that = this;
        jQuery('head').append(
          jQuery('<link rel="stylesheet" type="text/css" />').attr(
            'href',
            'https://cdn.plyr.io/3.6.3/plyr.css'
          )
        );
        jQuery.getScript(
          'https://cdn.plyr.io/3.6.3/plyr.polyfilled.js',
          function() {
            that.reSetup();
          }
        );
      } else {
        this.reSetup();
      }
    },
    reSetup: function() {
      if (this.player) {
        this.player.destroy();
      }
      // eslint-disable-next-line no-undef
      this.player = new Plyr(document.getElementById('player'));
      this.loadSources();
      this.player.play();
    },
    setType: function(type) {
      this.playerType = type;
      this.reSetup();
    },
    loadSources: function() {
      if (this.type === 'audio') return this.loadAudio();
      else return this.loadVideo();
    },
    loadAudio: function() {
      if (this.audio.length === 0) return '';
      if (this.player) {
        this.player.source = {
          type: 'audio',
          title: this.title,
          sources: [
            {
              src: this.audio,
              type: 'audio/mp3'
            }
          ]
        };
      } else {
        console.error('no player', this.player);
      }
      return this.audio;
    },
    loadVideo: function() {
      if (this.video.length === 0) return '';
      if (this.player) {
        this.player.source = {
          type: 'video',
          title: this.title,
          sources: [
            {
              src: this.video,
              type: 'video/mp4'
            }
          ]
        };
      } else {
        console.error('no player', this.player);
      }
      return this.video;
    }
  },
  mounted: function() {
    this.$nextTick(function() {
      this.setup();
    });
  },
  beforeDestroy: function() {
    if (this.player) {
      this.player.destroy();
    }
  },

  watch: {
    audio: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reSetup();
      }
    }
  }
};
</script>
