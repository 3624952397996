<template>
    <div class="row">
        <div class="col-md-12" v-for="item in multipleSeries" v-bind:key="item.id">
            <single-series-list-card v-bind:site="site" v-bind:series="item">
            </single-series-list-card>
        </div>
    </div>
</template>

<script>
import config from './config.js';
export default {
  name: 'series-latest',
  props: ['limit', 'inGroup', 'site'],
  data() {
    return { multipleSeries: [] };
  },
  mounted() {
    var self = this;
    jQuery
      .ajax({
        url: config.getSermonsHost() + '/sermon/series-data',
        method: 'GET',
        data: {
          filter: {
            groupCode: this.inGroup
          },
          limit: this.limit
        }
      })
      .done(function(data) {
        self.multipleSeries = data;
      })
      .fail(function(error) {
        console.error(error);
      });
  }
};
</script>
