<template>
    <div id="podcast"></div>
</template>

<script>
import config from './config.js';

export default {
  name: 'sermon-podlove',
  props: {
    filterBy : {
      type: Object,
      required: true
    }
  },
  mounted() {
    var params = {
      filter: {
        groupCode: this.filterBy['groupCode'],
        language: this.filterBy['language']
      }
    };
    var that = this;
    jQuery.get(config.getSermonsHost() + '/sermon/info?').done(function(data) {
      window.podcastDataNoFilter = {
        title: data['name'] + ' - ' + that.filterBy['groupCode'],
        subtitle: data['subtitle'],
        description: data['description'],
        cover: data['cover'],
        feeds: [
          {
            type: 'audio',
            format: 'mp3',
            url:
              config.getSermonsHostForPodcast() +
              '/sermon/rss?' +
              jQuery.param(params)
          }
        ]
      };

      if (document.getElementById('podcast')) {
        var el = document.createElement('script');
        el.setAttribute('type', 'text/javascript');
        el.setAttribute(
          'src',
          'https://cdn.podlove.org/subscribe-button/javascripts/app.js'
        );
        el.setAttribute('class', 'podlove-subscribe-button');
        el.setAttribute('data-language', 'de');
        el.setAttribute('data-size', 'small');
        el.setAttribute('data-json-data', 'podcastDataNoFilter');
        el.setAttribute('data-color', '#0078c1');
        el.setAttribute('data-format', 'rectangle');
        el.setAttribute('data-style', 'outline');

        document.getElementById('podcast').appendChild(el);

        if (!document.getElementById('feed-link')) {
          var link = document.createElement('link');
          link.id = 'feed-link';
          link.rel = 'alternate';
          link.type = 'application/rss+xml';
          link.title = data['name'] + ' - ' + that.filterBy['groupCode'];
          link.href =
            config.getSermonsHostForPodcast() +
            '/sermon/rss?' +
            jQuery.param(params);
          document.head.appendChild(link);
        }
      }
    });
  }
};
</script>
