<template>
    <div class="row sermon-card">
        <div v-on:click="play(sermon.id)" class="col-xs-3 date-box">
            <div class="day">{{ day }}</div>
            <div class="month">{{month}}</div>
        </div>
        <div class="col-xs-9">
            <a v-on:click="play(sermon.id)" class="title">{{sermon.title}}</a>
            <span class="speaker">{{sermon.speaker}}</span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'single-sermon-card',
  props: {
    sermon : {
      type: Object,
      required: true
    },
    site : {
      type: String
    }
  },
  data() {
    return {
      day: new Date(this.sermon.utcDate).getDate(),
      month: this.sermon.dateLocale.shortMonth
    };
  },
  methods: {
    play: function(sermon_id) {
      location.href = this.site + '?id=' + sermon_id;
    }
  }
};
</script>
