<template>
  <div v-if="loaded">
    <slot></slot>
  </div>
</template>

<script>
import config from './config.js';

export default {
  name: 'sermon-container',
  props: {
    theme: {
      type: String,
      default: undefined
    },  
    host: {
      type: String,
      default: undefined
    },
    staticSite: {
      type: String,
      default: undefined
    },
  },
  data: () => {
    return {
      loaded: false,
    }
    
  },
  created: function() {
    if(this.host != undefined) {
      window.SERMONS_HOST = this.host;
    }
    if(this.staticSite != undefined) {
      window.SERMONS_STATIC_SITE = this.staticSite;
    }
    if(this.theme != undefined) {
      jQuery('<link>')
        .appendTo('head')
        .attr({
          type: 'text/css', 
          rel: 'stylesheet',
          href: config.getSermonsHost() + '/stylesheets/'+this.theme+'/style.css'
        });
    }
    this.loaded = true;
  },

};
</script>
