<template>
    <div class="row series-card">
        <div v-on:click="play(series.name)" class="col-xs-3">
            <img :src="series.image" />
        </div>
        <div class="col-xs-9">
            <h2 v-on:click="play(series.name)">{{series.name}}</h2>
            <a v-on:click="play(series.name)">{{series.count}} Predigten anhören</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'single-series-list-card',
  props: ['series', 'site'],
  data() {
    return {};
  },
  methods: {
    play: function(series_name) {
      location.href = this.site + '?seriesName=' + series_name;
    }
  }
};
</script>
