function scriptPath() {
  var scripts = document.getElementsByTagName("SCRIPT");
  var path = "";
  if (scripts && scripts.length > 0) {
    for (let i in scripts) {
      if (scripts[i].src && scripts[i].src.match(/sermons\.umd\.min\.js$/)) {
        path = scripts[i].src.replace(/(.*)\/sermons\.umd\.min\.js$/, "$1");
        break;
      }
    }
  }
  return path;
}

export default {
  getSermonsHost() {
    if (window.SERMONS_HOST) {
      return window.SERMONS_HOST;
    } else {
      return scriptPath().replace("/vue/dist", "");
    }
  },
  getSermonsHostForPodcast() {
    let defaultHost = this.getSermonsHost();
    return defaultHost;
  },
  getStaticSite() {
    return window.SERMONS_STATIC_SITE;
  },
  getLanguage() {
    try {
      var lang = localStorage.getItem("language");
      if (lang.length > 0) return lang;
    } catch (e) {
      console.log("could not get language");
    }
    var language = window.navigator.language || window.navigator.userLanguage;
    if (language !== "de" && language !== "ru")
      language = "";
    return language;
  },
  setLanguage(lang) {
    try {
      localStorage.setItem("language", lang);
    } catch (e) {
      console.log("could not set language")
    }
  }
}
