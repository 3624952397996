<template>
  <div class="row">
    <div :class="cssClass" v-for="item in sermons" v-bind:key="item.id">
      <single-sermon-card v-bind:site="site" :sermon.prop="item"></single-sermon-card>
    </div>
  </div>
</template>

<script>
import config from './config.js';
export default {
  name: 'sermon-latest',
  props: {
    limit: {
      type: String,
      default: ''
    },
    inGroup: {
      type: String,
      default: ''
    },
    site: {
      type: String,
      default: ''
    },
    columnWidth: {
      type: String,
      default: '4'
    }
  },
  data() {
    return { sermons: [] };
  },
  computed: {
    cssClass() {
      return 'col-md-' + this.columnWidth;
    }
  },
  mounted() {
    var self = this;
    jQuery
      .ajax({
        url: config.getSermonsHost() + '/sermon/list',
        method: 'GET',
        data: {
          filter: {
            groupCode: this.inGroup
          },
          limit: this.limit
        }
      })
      .done(function(data) {
        self.sermons = data.sermons;
      })
      .fail(function(error) {
        console.error(error);
      });
  }
};
</script>
