import Vue from "vue";
Vue.config.devtools = process.env.NODE_ENV === 'development'

import vueCustomElement from 'vue-custom-element';
Vue.use(vueCustomElement);

import SermonLatest from "./sermon-latest.vue";
import SermonTable from "./sermon-table.vue";
import SeriesTable from "./series-table.vue";
import SermonList from "./sermon-list.vue";
import SermonListItem from "./sermon-list-item.vue";

import SeriesLatest from "./series-latest.vue";
import SingleSeriesListCard from "./single-series-list-card.vue";
import SingleSermon from "./single-sermon.vue";
import SingleSermonCard from "./single-sermon-card.vue";
import SingleSermonPlayer from "./single-sermon-player.vue";
import SermonPlayer from "./sermon-player.vue";
import SermonShare from "./sermon-share.vue";

import SermonPodlove from "./sermon-podlove.vue";
import SermonContainer from "./sermon-container.vue";

Vue.customElement("series-latest", SeriesLatest);
Vue.customElement("sermon-table", SermonTable);
Vue.customElement("series-table", SeriesTable);
Vue.customElement("sermon-list", SermonList);
Vue.customElement("sermon-list-item", SermonListItem);

Vue.customElement("sermon-latest", SermonLatest);

Vue.customElement("single-series-list-card", SingleSeriesListCard);
Vue.customElement("single-sermon", SingleSermon);

Vue.customElement("single-sermon-card", SingleSermonCard);
Vue.customElement("single-sermon-player", SingleSermonPlayer);
Vue.customElement("sermon-player", SermonPlayer);
Vue.customElement("sermon-share", SermonShare);

Vue.customElement("sermon-podlove", SermonPodlove);

Vue.customElement("sermon-container", SermonContainer);
