<template>
    <div class="sermon-share">
        <a target="_blank" rel="noopener noreferrer" :href="wa">
            <i class="fa fa-ad fa-whatsapp"></i> per Whatsapp teilen
        </a>
        <a target="_blank" rel="noopener noreferrer" :href="email">
            <i class="fa fa-envelope"></i> per EMail teilen
        </a>
        <a target="_blank" rel="noopener noreferrer" :href="shareLink">
            <i class="fa fa-link"></i> Link öffnen
        </a>
        <a @click="copyToClipboard()">
            <i class="fa fa-link"></i> Link kopieren
        </a>
    </div>
</template>

<script>
export default {
  name: 'sermon-share',
  props: ['url', 'title'],
  computed: {
    shareTitle: function() {
      return this.title;
    },
    shareLink: function() {
      return this.url;
    },
    shareText: function() {
      return this.url;
    },
    wa: function() {
      return '//wa.me/?text=' + this.shareText;
    },
    email: function() {
      return 'mailto:?subject=' + this.shareTitle + '&body=' + this.shareText;
    },
  },
  methods: {
     copyToClipboard: function() {
      navigator.clipboard.writeText(this.url);
    }
  }
};
</script>
