<template>
    <div>
        <div class="row" v-if="sermon != null">
            <div class="col-xs-12">
                <single-sermon-player :sermon.prop="sermon"></single-sermon-player>
            </div>
        </div>

        <div class="row" v-if="showSearchBar">
          <div class="col-xs-12 sermon-search form-inline">
            <div class="search-filter form-group has-feedback has-feedback-left">
              <i class="form-control-feedback fa fa-search"></i>
              <input
                class="form-input form-control"
                v-on:input="filterByAll()"
                debounce="500"
                v-model="filter.all"
                placeholder="Suche…"
              />

            </div>

            <div class="speaker-filter form-group">
              <select
                class="form-control vue-select disable-select"
                v-model="filter.speaker"
                v-on:change="filterBySpeaker(filter.speaker)"
              >
                <option value>Nach Prediger filtern</option>
                <option v-for="speaker in speakers" v-bind:value="speaker" v-bind:key="speaker">{{ speaker }}</option>
              </select>
            </div>

            <div class="series-filter form-group">
              <select
                class="form-control vue-select disable-select"
                v-model="filter.seriesName"
                v-on:change="filterBySeries(filter.seriesName)"
              >
                <option value>Nach Serie filtern</option>
                <option v-for="seriesName in series" v-bind:value="seriesName" v-bind:key="seriesName">{{ seriesName }}</option>
              </select>
            </div>

            <div class="language-filter form-group">
              <div v-if="languages.length > 1">
                <div v-for="language in languages" v-bind:value="language" v-bind:key="language">
                  <a v-on:click="filterByLanguage(language)">
                    <img
                      class="language-flag"
                      width="25"
                      height="20"
                      v-bind:class="{ active: language == filter.language }"
                      v-bind:src="languageImage(language)"
                    />
                  </a>
                </div>
              </div>
              <i class="fa fa-times table-clear" aria-hidden="true" v-on:click="clear()"></i>
            </div>
          </div>
        </div>

        <div class="row">
            <div v-for="item in sermons" :key="item.id">
                <sermon-list-item
                    :sermon.prop="item"
                    v-on:play="play(item)"
                ></sermon-list-item>
            </div>
        </div>
        <div class="row" v-if="pages > 1">
            <div class="col-xs-12">
                <nav aria-label="Page navigation">
                    <ul class="pagination">
                        <li v-bind:class="{ disabled: this.prevNotAvailable }">
                            <a v-on:click="goPrev()" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li
                            v-for="page in pagesArray"
                            v-bind:class="{ active: isCurrentPage(page) }"
                            v-bind:key="page"
                        >
                            <a v-on:click="goToPage(page)">{{ page }}</a>
                        </li>
                        <li v-bind:class="{ disabled: this.nextNotAvailable }">
                            <a v-on:click="goNext()" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import config from './config.js';
import api from './api.js';
import params from './params.js';
export default {
  name: 'sermon-list',
  props: {
    inGroup: {
      type: String,
      default: ''
    },
    inSeries: {
      type: String,
      default: ''
    },
    site: {
      type: String,
      default: ''
    },
    showDirect: {
      default: false,
      type: Boolean
    },
    showSearchBar: {
      default: true,
      type: Boolean
    },
    language: {
      default: config.getLanguage(),
      type: String
    }
  },
  data() {
    var urlParams = params.getUrlParams();
    var seriesName = urlParams.seriesName || this.inSeries;
    if (seriesName == undefined) {
      seriesName = '';
    }
    let safeLanguage = undefined;
    if (this.showSearchBar != false) {
      safeLanguage = this.language;
    }
    return {
      sermons: [], // all sermons to show
      sermon: null, // current sermon in player
      series: [], // all series
      speakers: [],
      languages: [],
      filter: {
        //current filter
        groupCode: this.inGroup,
        all: null,
        speaker: '',
        seriesName: seriesName,
        language: safeLanguage //use safeLanguage because when we hide the searchbar its impossible to change language
      },
      limit: 20,
      offset: 0,
      total: 0,
      count: 0
    };
  },
  computed: {
    pagesArray: function() {
      var i = 0;
      var ret = [];
      if (this.pages > 10) {
        const list = [
          1,
          2,
          3,
          4,
          5,
          this.currentPage - 1,
          this.currentPage,
          this.currentPage + 1,
          this.currentPage + 2,
          this.pages - 2,
          this.pages - 1,
          this.pages
        ];
        const unique = [...new Set(list)];
        ret = unique.filter(x => x > 0 && x <= this.pages);
      } else {
        for (i = 1; i <= this.pages; i++) {
          ret.push(i);
        }
      }

      return ret;
    },
    pages: function() {
      return Math.floor(this.total / this.limit);
    },
    currentPage: function() {
      return Math.floor((this.offset + this.limit) / this.limit);
    },
    prevNotAvailable: function() {
      return this.currentPage <= 1;
    },
    nextNotAvailable: function() {
      return this.currentPage >= this.pages;
    },
  },

  methods: {
    goToPage: function(page) {
      this.offset = (page - 1) * this.limit;
      this.getSermons();
    },
    languageImage: function(lang) {
      return config.getSermonsHost() + '/images/' + lang + '.png';
    },
    goPrev: function() {
      if (this.prevNotAvailable) return;
      this.goToPage(this.currentPage - 1);
    },
    goNext: function() {
      if (this.nextNotAvailable) return;
      this.goToPage(this.currentPage + 1);
    },
    isCurrentPage: function(page) {
      return page == this.currentPage;
    },
    resetPagination: function() {
      this.limit = 20;
      this.offset = 0;
    },
    clear: function() {
      this.filter.language = '';
      this.filter.seriesName = '';
      this.filter.speaker = '';
      this.filter.all = '';
      this.resetPagination();
      this.getSermons();
    },

    getSermons: function(doNotReset = false) {
      return api
        .sermons(
          this.filter,
          this.limit,
          this.offset,
          this.sort,
          this.showDirect
        )
        .done(data => {
          console.log(data);
          if (data.count > 0 || doNotReset == true) {
            this.sermons = data.sermons;
            this.total = data.total;
            this.count = data.count;
          } else {
            config.setLanguage(this.languages[0]);
            this.filter.language = this.languages[0];
            return this.getSermons(true);
          }
        });
    },

    getSeries: function() {
      api
        .series(this.filter.groupCode, this.filter.language)
        .done(data => (this.series = data));
    },

    getLanguages: function() {
      api
        .languages(this.filter.groupCode)
        .done(data => (this.languages = data));
    },

    getSpeakers: function() {
      api
        .speakers(this.filter.groupCode, this.filter.language)
        .done(data => (this.speakers = data));
    },

    scrollToTop: function() {
      if (jQuery('.single-sermon').length > 0) {
        jQuery('html, body').animate(
          { scrollTop: jQuery('.single-sermon').offset().top },
          500
        );
      }
    },

    play: function(sermon) {
      this.sermon = sermon;
      this.scrollToTop();
      api.hit(sermon.id);
    },

    playById: function(id) {
      let self = this;
      api
        .singleSermon(id)
        .done(function(data) {
          self.play(data['sermons'][0]);
        })
        .fail(function(error) {
          console.error(error);
        });
    },

    filterByAll: function() {
      this.resetPagination();
      this.getSermons();
    },
    filterBySpeaker: function(speaker) {
      this.filter.speaker = speaker;
      this.resetPagination();
      this.getSermons();
    },
    filterBySeries: function(series) {
      if (this.filter.speaker.length > 0 && series.length > 0) {
        this.filter.speaker = '';
      }
      this.filter.seriesName = series;
      this.resetPagination();
      this.getSermons();
    },
    filterByLanguage: function(language) {
      config.setLanguage(language);
      this.filter.language = language;
      if (this.filter.seriesName.length > 0 && language.length > 0) {
        this.filter.seriesName = '';
      }
      this.resetPagination();
      this.getSpeakers();
      this.getSeries();
      this.getSermons();
    },

  },
  mounted() {
    this.getSermons();    
    this.getSeries();
    this.getSpeakers();
    this.getLanguages();
    var urlParams = params.getUrlParams();
    if (urlParams.id && urlParams.id.length > 0) {
      this.playById(urlParams.id);
    }
    
  }
};
</script>
