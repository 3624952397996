<template>
  <div>
    <single-sermon-player :sermon.prop="sermon"></single-sermon-player>
    <div v-if="showSearchBar">
      <div class="col-md-12">
        <div class="row sermon-search">
          <div class="form-inline">
            <div class="form-group col-md-3">
              <input
                class="form-input form-control"
                v-on:input="filterByAll()"
                debounce="500"
                v-model="filter.all"
                placeholder="Suche…"
              />
            </div>

            <div class="speaker-filter form-group col-md-3">
              <select
                class="form-control vue-select disable-select"
                v-model="filter.speaker"
                v-on:change="filterBySpeaker(filter.speaker)"
              >
                <option value>Nach Prediger filtern</option>
                <option v-for="speaker in speakers" v-bind:value="speaker" v-bind:key="speaker">{{ speaker }}</option>
              </select>
            </div>

            <div class="series-filter form-group col-md-4">
              <select
                class="form-control vue-select disable-select"
                v-model="filter.seriesName"
                v-on:change="filterBySeries(filter.seriesName)"
              >
                <option value>Nach Serie filtern</option>
                <option v-for="seriesName in series" v-bind:value="seriesName" v-bind:key="seriesName">{{ seriesName }}</option>
              </select>
            </div>

            <div class="language-filter form-group col-md-2">
              <div v-if="languages.length > 1">
                <div v-for="language in languages" v-bind:value="language" v-bind:key="language">
                  <a v-on:click="filterByLanguage(language)">
                    <img
                      class="language-flag"
                      width="25"
                      height="20"
                      v-bind:class="{ active: language == filter.language }"
                      v-bind:src="languageImage(language)"
                    />
                  </a>
                </div>
              </div>
              <i class="fa fa-times table-clear" aria-hidden="true" v-on:click="clear"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table class="sermon-table table table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Titel</th>
              <th v-if="showScripture">Stelle</th>
              <th>Prediger</th>
              <th v-if="showSeries">Serie</th>
              <th>Datum</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sermon in sermons" v-bind:key="sermon.id">
              <td class="play" v-on:click="play(sermon)">
                <div>
                  <i class="fa fa-play"></i>
                </div>
              </td>
              <td class="title">{{sermon.title}}</td>
              <td v-if="showScripture">{{sermon.scripture}}</td>
              <td>
                <a v-on:click="filterBySpeaker(sermon.speaker)">{{sermon.speaker}}</a>
              </td>
              <td v-if="showSeries">
                <a v-on:click="filterBySeries(sermon.seriesName)">{{sermon.seriesName}}</a>
              </td>
              <td>{{sermon.date}}</td>
              <td v-html="downloadLinks(sermon)"></td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation">
          <ul class="pagination">
            <li v-bind:class="{'disabled': this.prevNotAvailable}">
              <a v-on:click="goPrev()" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li v-for="page in pagesArray" v-bind:class="{'active': isCurrentPage(page)}" v-bind:key="page">
              <a v-on:click="goToPage(page)">{{page}}</a>
            </li>
            <li v-bind:class="{'disabled': this.nextNotAvailable}">
              <a v-on:click="goNext()" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
        <br />
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-lg-10 text">
        <b>Nichts mehr verpassen.</b>
        Podcast abonnieren für automatische Benachrichtung bei neuen Predigen und leichtes anhören:
      </div>
      <div class="col-xs-12 col-lg-2 text">
        <sermon-podlove :filterBy.prop="filter"></sermon-podlove>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config.js';
import params from './params.js';

export default {
  name: 'sermon-table',
  props: {
    inGroup: {
      default: '',
      type: String
    },
    inSeries: {
      default: '',
      type: String
    },
    showSearchBar: {
      default: true,
      type: Boolean
    },
    showSeries: {
      default: true,
      type: Boolean
    },
    showScripture: {
      default: true,
      type: Boolean
    },
    showDirect: {
      default: false,
      type: Boolean
    },
    sort: {
      default: function() {
        return { date: 'desc' };
      },
      type: Object
    },
    language: {
      default: config.getLanguage(),
      type: String
    }
  },
  data() {
    var urlParams = params.getUrlParams();
    var seriesName = urlParams.seriesName || this.inSeries;
    if (seriesName == undefined) {
      seriesName = '';
    }
    
    let safeLanguage = undefined;
    if (this.showSearchBar != false) {
      safeLanguage = this.language;
    }

    return {
      sermons: [],
      series: [],
      speakers: [],
      languages: [],
      filter: {
        groupCode: this.inGroup,
        all: null,
        speaker: '',
        seriesName: seriesName,
        language: safeLanguage //use safeLanguage because when we hide the searchbar its impossible to change language
      },
      limit: 20,
      offset: 0,
      total: 0,
      count: 0,
      sermon: null
    };
  },
  computed: {
    pagesArray: function() {
      var i = 0;
      var ret = [];
      for (i = 1; i <= this.pages; i++) {
        ret.push(i);
      }
      return ret;
    },
    pages: function() {
      return Math.floor(this.total / this.limit);
    },
    currentPage: function() {
      return Math.floor((this.offset + this.limit) / this.limit);
    },
    prevNotAvailable: function() {
      return this.currentPage <= 1;
    },
    nextNotAvailable: function() {
      return this.currentPage >= this.pages;
    },
  },

  methods: {
    goToPage: function(page) {
      this.offset = (page - 1) * this.limit;
      this.get();
    },
    goPrev: function() {
      if (this.prevNotAvailable) return;
      this.goToPage(this.currentPage - 1);
    },
    goNext: function() {
      if (this.nextNotAvailable) return;
      this.goToPage(this.currentPage + 1);
    },
    isCurrentPage: function(page) {
      return page == this.currentPage;
    },
    downloadLinks: function(sermon) {
      var ret = '';
      var index;
      for (index = 0; index < sermon.audio.length; ++index) {
        ret +=
          "<a v-on:click='hit(sermon.id)' href='" +
          sermon.audio[index] +
          "' class='fa fa-headphones'></a> ";
      }
      for (index = 0; index < sermon.video.length; ++index) {
        ret +=
          "<a v-on:click='hit(sermon.id)' href='" +
          sermon.video[index] +
          "' class='fa fa-film'></a> ";
      }
      for (index = 0; index < sermon.other.length; ++index) {
        ret +=
          "<a v-on:click='hit(sermon.id)' href='" +
          sermon.other[index] +
          "' class='fa fa-file-pdf-o'></a> ";
      }

      if (sermon.youtube) {
        ret +=
          "<a v-on:click='hit(sermon.id)' href='" +
          sermon.youtube +
          "' class='fa fa-youtube'></a> ";
      }
      return ret;
    },
    languageImage: function(lang) {
      return config.getSermonsHost() + '/images/' + lang + '.png';
    },
    play: function(sermon) {
      this.sermon = sermon;
      if (jQuery('.single-sermon').length > 0) {
        jQuery('html, body').animate(
          {
            scrollTop: jQuery('.single-sermon').offset().top
          },
          500
        );
      }
      this.hit(sermon.id);
    },
    playById: function(id) {
      let self = this;
      jQuery
        .ajax({
          url: config.getSermonsHost() + '/sermon/list',
          method: 'GET',
          data: {
            filter: {
              id: id
            }
          }
        })
        .done(function(data) {
          self.play(data['sermons'][0]);
        })
        .fail(function(error) {
          console.error(error);
        });
    },
    filterByAll: function() {
      this.resetPagination();
      this.get();
    },
    filterBySpeaker: function(speaker) {
      this.filter.speaker = speaker;
      this.resetPagination();
      this.get();
    },
    filterBySeries: function(series) {
      if (this.filter.speaker.length > 0 && series.length > 0) {
        this.filter.speaker = '';
      }
      this.filter.seriesName = series;
      this.resetPagination();
      this.get();
    },
    filterByLanguage: function(language) {
      config.setLanguage(language);
      this.filter.language = language;
      if (this.filter.seriesName.length > 0 && language.length > 0) {
        this.filter.seriesName = '';
      }
      this.resetPagination();
      this.getSpeakers();
      this.getSeries();
      this.get();
    },
    resetPagination: function() {
      this.limit = 20;
      this.offset = 0;
    },
    get: function(doNotReset = false, self) {
      if (!self) self = this;
      return jQuery
        .ajax({
          url: config.getSermonsHost() + '/sermon/list',
          method: 'GET',
          data: {
            filter: self.filter,
            limit: self.limit,
            offset: self.offset,
            sort: self.sort,
            showDirect: self.showDirect ? 1 : 0
          }
        })
        .done(function(data) {
          if (data.count > 0 || doNotReset == true) {
            self.sermons = data.sermons;
            self.total = data.total;
            self.count = data.count;
          } else {
            config.setLanguage(self.languages[0]);
            self.filter.language = self.languages[0];
            return self.get(true, self);
          }
        })
        .fail(function(error) {
          console.error(error);
        });
    },
    getSeries: function() {
      var self = this;
      jQuery
        .ajax({
          url: config.getSermonsHost() + '/sermon/series',
          method: 'GET',
          data: {
            group: self.filter.groupCode,
            language: self.filter.language
          }
        })
        .done(function(data) {
          self.series = data;
        })
        .fail(function(error) {
          console.error(error);
        });
    },
    getSpeakers: function() {
      var self = this;
      jQuery
        .ajax({
          url: config.getSermonsHost() + '/sermon/speakers',
          method: 'GET',
          data: {
            group: self.filter.groupCode,
            language: self.filter.language
          }
        })
        .done(function(data) {
          self.speakers = data;
        })
        .fail(function(error) {
          console.error(error);
        });
    },
    getLanguages: function() {
      var self = this;
      jQuery
        .ajax({
          url: config.getSermonsHost() + '/sermon/languages',
          method: 'GET',
          data: {
            group: self.filter.groupCode
          }
        })
        .done(function(data) {
          self.languages = data;
        })
        .fail(function(error) {
          console.error(error);
        });
    },
    clear: function() {
      this.filter.language = '';
      this.filter.series = '';
      this.filter.speaker = '';
      this.filter.all = '';
      this.resetPagination();
      this.get();
    },
    hit: function(id) {
      try {
        var hit = localStorage.getItem('hit_' + id);
        if (hit == id) return;
        hit = localStorage.setItem('hit_' + id, id);
        jQuery.get(config.getSermonsHost() + '/sermon/hit?id=' + id);
      } catch (e) {
        console.error(e);
      }
    }
  },
  mounted: function() {
    this.get();
    this.getSeries();
    this.getSpeakers();
    this.getLanguages();
    var urlParams = params.getUrlParams();
    if (urlParams.id && urlParams.id.length > 0) {
      this.playById(urlParams.id);
    }
  }
};
</script>
