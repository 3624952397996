<template>
    <div v-if="sermon != null">
      <div v-if="sermon.series !== ''">
        <sermon-list :in-group="sermon.groupCode" :in-series="sermon.seriesName" :show-search-bar="false" :show-series="false"
                      :sort.prop="{'date': 'asc'}"></sermon-list>
      </div>
      <div v-else>
        <single-sermon-player :sermon.prop="sermon"></single-sermon-player>
      </div>
    </div>
</template>

<script>
import config from './config.js';
import params from './params.js';

export default {
  name: 'single-sermon',
  props: {
    sermonId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      sermon: null
    };
  },
  created: function() {
    this.get();
  },
  methods: {
    get: function() {
      let id = this.sermonId;
      if (id === undefined) {
        var urlParams = params.getUrlParams();
        id = urlParams['id'];
      }

      var self = this;
      return jQuery
        .ajax({
          url: config.getSermonsHost() + '/sermon/list',
          method: 'GET',
          data: {
            filter: { id: id },
            limit: 1
          }
        })
        .done(function(data) {
          if (data.count > 0) {
            self.sermon = data.sermons[0];
            document.title = self.sermon.title;
          } else {
            console.error('error: no sermons found');
          }
        })
        .fail(function(error) {
          console.error(error);
        });
    }
  }
};
</script>
